import { ServiceFactory } from '@/services/ServiceFactory'
const _videosService = ServiceFactory.get('BarVideoService')
export default {
  data () {
    return {
      loading: false,
      dialog: false,
      postData: {
        zbBarId: '',
        tiktokVideoUrl: '',
        order: '',
        thumbnailUrl: null
      },
      showError: false,
      imageBanner: null,
      items: [ 1, 2, 3 ],
      thumbnail: {
        image: null
      }
    }
  },
  props: {
    itemData: Object,
    action: String,
    zbBarId: String,
    orders: Array
    // modal: Boolean
  },
  watch: {
    itemData: {
      handler: 'setItem',
      immediate: true
    },
    action: {
      handler: 'initAction',
      immediate: true
    },
    zbBarId: {
      handler: 'initAction',
      immediate: true
    },
    orders: {
      handler: 'initAction',
      immediate: true
    }
  },
  methods: {
    async initAction () {
      console.log(this.action)
      console.log(this.zbBarId)
      console.log(this.orders)
      this.items = this.orders
      // if (this.modal) {
      //   this.dialog = true
      // }
    },
    async setItem () {
      if (this.itemData) {
        this.postData.tiktokVideoUrl = this.itemData.video.tiktokVideoUrl
        this.postData.order = this.itemData.video.order
        this.thumbnail.image = this.itemData.video.meta.thumbnail_url
        // this.items.push(this.itemData.video.order)
      }
      // this.items.sort()
      // console.log(this.itemData.video)
      // try {
      //   this.companies = await _barsService.listBar()
      // } catch (error) {
      //   console.log(error)
      // }
    },
    onFocus () {
      console.log('focus')
      this.items.push(this.itemData.video.order)
      this.items.sort()
    },
    closeModal () {
      // this.imgBanner = {
      //   image: ''
      // }
      // this.image = {
      //   width: '',
      //   height: ''
      // }
      this.postData = {
        tiktokVideoUrl: '',
        order: '',
        thumbnailUrl: null
      }
      this.thumbnail.image = null
      console.log('closeeeeee')
      this.loading = false
      this.dialog = false
      this.$emit('closeThisModal')
    },
    clearForm () {
      this.postData = {
        tiktokVideoUrl: '',
        order: '',
        thumbnailUrl: null
      }
      this.thumbnail.image = null
    },
    deleteImg () {
      this.loading = true
      this.$swal({
        title: '¿Está seguro de eliminar la imágen?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            console.log(this.zbBarId, this.imgBanner.image)
            // const pd = {
            //   image: this.imgBanner.image
            // }
            // await _barsService.delCarImg(this.zbBarId, this.imgBanner)
            this.$swal(
              'Imágen eliminada',
              'Se eliminó correctamente',
              'success'
            ).then(async (result) => {
              // this.initialize()
              this.loading = false
              this.$emit('closeThisModal')
            })
          } catch (error) {
            this.loading = false
            console.log(error)
            this.$notify({
              title: 'Error:',
              type: 'error',
              text: 'La imágen no se pudo eliminar.'
            })
          }
        }
      })
    },
    async submit () {
      this.loading = true
      console.log(this.postData)
      // console.log(this.imageBanner)
      if (this.action === 'create') {
        try {
          // this.postData.order = parseInt(this.postData.order)
          this.postData.zbBarId = this.zbBarId
          await _videosService.create(this.postData)
          this.$notify({
            type: 'success',
            text: 'Video agredado correctamente'
          })
          this.loading = false
          this.clearForm()
          this.$emit('closeThisModal')
          // this.$emit('closeModal')
        } catch (error) {
          console.log(error)
          this.loading = false
          this.$notify({
            type: 'error',
            text: 'Ocurrió un error al guardar el video'
          })
        }
      } else {
        try {
          // this.postData.order = parseInt(this.postData.order)
          console.log(this.zbBarId, this.itemData.video.id)
          this.postData.zbBarId = this.zbBarId
          var resp = await _videosService.edit(this.zbBarId, this.itemData.video.id, this.postData)
          console.log(resp)
          // await _videosService.create(this.postData)
          this.$notify({
            type: 'success',
            text: 'Video modificado correctamente'
          })
          this.loading = false
          this.clearForm()
          this.$emit('closeThisModal')
          // this.$emit('closeModal')
        } catch (error) {
          console.log(error)
          this.loading = false
          this.$notify({
            type: 'error',
            text: 'Ocurrió un error al modificar el video'
          })
        }
      }
    },
    handleThumbnailUpload (e) {
      console.log(e)
      this.postData.thumbnailUrl = e.target.files[0]
      console.log(e.target.files[0])
      this.previewThumbnail(e)
      // console.log(this.image)
    },
    previewThumbnail (event) {
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.readAsDataURL(input.files[0])
        reader.onload = (e) => {
          let img = new Image()
          // img.onload = () => {
          //   this.image.width = img.width
          //   this.image.height = img.height
          // }
          img.src = e.target.result
          this.thumbnail.image = e.target.result
        }
      }
    }
  }
}
