import { ServiceFactory } from '@/services/ServiceFactory'
import ZbVideoModal from './video-modal'
const _videosService = ServiceFactory.get('BarVideoService')
const _barsService = ServiceFactory.get('BarsService')
export default {
  name: 'ZbTabPremiumComponent',
  components: {
    ZbVideoModal
  },
  data () {
    return {
      premium: true,
      zbBarId: '',
      currentItem: null,
      submitAction: 'create', // String 'create' || 'edit'
      dialog: false,
      videosData: { selectOrder: [0] },
      videos: [],
      barData: {},
      loading: false,
      loadingCard: false
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    async initialize () {
      // console.log(this.$vuetify)
      this.loadingCard = true
      if (this.$route.params.id) {
        this.zbBarId = this.$route.params.id
        console.log(this.zbBarId)
        try {
          this.barData = await _barsService.show(this.$route.params.id)
          this.premium = this.barData.bar.isPremium
          console.log(this.barData)
          if (this.premium) {
            this.videosData = await _videosService.index(this.$route.params.id)
            console.log(this.videosData)
          }
          if (this.videosData.videos) {
            this.videos = this.videosData.videos
            // console.log('bardata videos')
            // if (this.barData.bar.images.carousel) {
            //   console.log('bardata videos carrousel')
            //   this.carouselImg = this.barData.bar.images.carousel
            // }
          }
          // if (this.barData.bar.images === null) {
          //   this.barData.bar.images = {}
          // }
          this.loadingCard = false
        } catch (error) {
          console.log(error)
          this.$notify({
            type: 'error',
            text: error.msg
          })
          this.loadingCard = false
        }
      } else {
        // console.log('sin parametros')
        // this.loadingForm = false
        // this.$route.push('/')
        this.$router.push({ name: 'companies' })
      }
    },
    async changePremium () {
      this.loading = true
      var premiumState = ''
      this.premium ? premiumState = 'Desabilitar' : premiumState = 'Habilitar'
      this.$swal({
        title: '¿' + premiumState + ' Perfil Premium de TikTok en este negocio?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            console.log(this.premium)
            var resp = await _barsService.premiumState(this.zbBarId, { isPremium: !this.premium })
            console.log(resp)
            if (this.premium) {
              this.$notify({
                type: 'success',
                text: 'El negocio cuenta con función Premium'
              })
            } else {
              this.$notify({
                type: 'success',
                text: 'El negocio ya no cuenta con función Premium'
              })
            }
            this.loading = false
            this.initialize()
          } catch (error) {
            console.log(error)
            this.$notify({
              type: 'error',
              text: `Ocurrió un error modificar la cuenta`
            })
            this.loading = false
            this.initialize()
          }
          console.log('premium change')
        } else {
          this.loading = false
        }
      })
    },
    async deleteVideo (item) {
      this.$swal({
        title: '¿Desea eliminar este video?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            console.log(item)
            var resp = await _videosService.destroy(this.zbBarId, item.id)
            console.log(resp)
            this.$notify({
              type: 'success',
              text: 'Video eliminado correctamente'
            })
            this.initialize()
          } catch (error) {
            console.log(error)
            this.$notify({
              type: 'error',
              text: `Ocurrió un error al eliminar el video.`
            })
            this.initialize()
          }
        }
      })
    },
    async videoState (item) {
      var state = item.available
      var stateText = ''
      !state ? stateText = 'habilitar' : stateText = 'deshabilitar'
      this.$swal({
        title: '¿Desea ' + stateText + ' este video?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            console.log(item)
            var resp = await _videosService.state(this.zbBarId, item.id, { available: !state })
            console.log(resp)
            this.$notify({
              type: 'success',
              text: 'Video modificado correctamente'
            })
            this.initialize()
          } catch (error) {
            console.log(error)
            this.$notify({
              type: 'error',
              text: `Ocurrió un error al modificar el video.`
            })
            this.initialize()
          }
        }
      })
    },
    newVideoModal () {
      console.log('new video')
      this.dialog = true
      this.submitAction = 'create'
    },
    videoDialog (item) {
      console.log(item)
      this.dialog = true
      this.submitAction = 'edit'
      // this.videosData.selectOrder.push(item.order)
      this.currentItem = {
        video: item
      }
    },
    closeImgModal () {
      console.log('action on close modal')
      this.dialog = false
      this.initialize()
    },
    async move (m, i, item, order) {
      // console.log(m, i, item)
      // this.carouselImg.splice(i, 1)
      var newOrder = null
      if (m === 'l') {
        console.log(i, order, item)
        newOrder = order - 1
        // console.log('new order', newOrder, order)
        // this.carouselImg.splice(i - 1, 0, item)
      }
      if (m === 'r') {
        console.log(i, order, item)
        newOrder = order + 1
        // console.log('new order', newOrder, order)
        // this.carouselImg.splice(i + 1, 0, item)
      }
      console.log('new order:' + newOrder, 'current: ' + order, 'id:' + item.id)
      try {
        var resp = await _videosService.edit(this.zbBarId, item.id, { order: newOrder })
        console.log(resp)
        this.$notify({
          type: 'success',
          text: 'Orden de video cambiado correctamente'
        })
        this.initialize()
      } catch (error) {
        console.log(error)
        this.$notify({
          type: 'error',
          text: `Ocurrió un error al cambiar el orden.`
        })
        this.initialize()
      }
    }
  }
}
